/* -------------------------------------------------------------------------- */
/*                                    VARS                                    */
/* -------------------------------------------------------------------------- */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	button {
	  @apply transition-colors duration-300 ease-in-out;
	}
  
	button:hover {
	  @apply bg-contrast-900; /* Default hover background color */
	}
  }

:root {
	--icon-size: 20px;
	--logo-size: 40px;
	--text-color: #111111;
	--text-error: rgb(230, 39, 14);
	--background-color:#ffffff;
	--button-highlight:#FFA400;
	--highlight-color:#18a999;
	--primary-color: #111111;
	--contrast-color: #dce1de;
	--prompt: #111111;
	--prompt-in:#07393c;
	--prompt-in-symbol: #ffffff;
	--prompt-out:#FFA400;
	--prompt-in-dark:rgb(143, 220, 240);
	--text-color-dark: #EAF6FF;
	--contrast-color-dark:#6b6b6b;
	--contrast-color-dark-secondary:#424242;
	--background-color-dark: #333333;
	--background-color-contrast:rgb(235, 235, 235);
	--background-color-contrast-secondary:rgb(211, 211, 211);
	--background-color-contrast-dark:#282828;
	--background-color-contrast-dark-secondary:#424242;
	--primary-color-dark: #EAF6FF;
	--highlight-color-dark:#18a999;
	--button-highlight-dark:#FFA400;
	--notification-status: var(--bs-secondary);
	--prompt-dark: #FFA400;
	--loader-color: rgba(7, 57, 60, 0.70);
	--loader-color-dark:rgba(143, 220, 240, 0.90);
	--border-line-color: rgb(156, 156, 156);
	--border-line-color-dark: rgb(88, 88, 88);
	--font-size-prompt: 1em;
	--font-size-prompt: 1em;
	--font-weight-prompt : 450;
	--font-size-embeded: 0.7em;
	--UI-scaling-factor:1;
	--write-padding: 5px;
	--write-padding-right: 10px;
	--spacing-padding: 1rem;
	--paddingTop: 15px;
	--verticalGap:10px;
	--border-radius:4px;
	--HGap: 10px;
	--panels-border: var(--primary-color);
}

[data-theme="dark"] {
	--background-color: var(--background-color-dark);
	--background-color-contrast: var(--background-color-contrast-dark);
	--background-color-contrast-secondary:var(--background-color-contrast-dark-secondary);
	--text-color: var(--text-color-dark);
	--primary-color: var(--primary-color-dark);
	--prompt-in: var(--prompt-in-dark);
	--prompt : var(--prompt-dark);
	--highlight-color: var(--highlight-color-dark);
	--contrast-color: var(--contrast-color-dark);
	--border-line-color: var(--border-line-color-dark);
	--loader-color:var(--loader-color-dark);
	--button-hightlight: var(--button-highlight-dark);
	--panels-border: var(--primary-color-dark);

}

.App {
    all: unset;
}

.button_icon{
	align-self: center;
	background-color: transparent;
    width: var(--icon-size);
    height: auto;
}

button{
	display: flex;
	cursor: pointer;
}

button:hover{
	opacity: 1;
	background-color: theme('colors.neutral.900');
}

button:disabled{
	opacity: 0.5;
}

@layer components {
	::-webkit-scrollbar {
		width: 10px; /* Width for vertical scrollbars */
		height: 12px; /* Height for horizontal scrollbars */
	}

	::-webkit-scrollbar-track {
		background-color: transparent;
		border-radius: 10px;
		border: 2px solid transparent; /* Adds spacing between content and scrollbar */
	}

	::-webkit-scrollbar-thumb {
		width: 10px;
		background-color: theme('colors.contrast.800'); /* Thumb color */
	}

	::-webkit-scrollbar-thumb:hover {
		background-color: theme('colors.contrast.50'); /* Thumb color on hover */
	}
}
