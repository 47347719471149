#navbar button:hover{
	transform: scale(1.3);
}

.navbar_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    height: fit-content;
    gap: 15px;
}