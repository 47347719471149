.embedded_menu {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    overflow-wrap: break-word;
    gap: 10px;
	padding: 10px;
    max-width: 100%;
    overflow-y: auto;
	border-radius: 4px;
}