#control_panel
{
    display: flex;
    flex-direction: column;
    width: 60%;
    max-height: 100vh;
    align-items: center;
    padding-top: 30px;
}

#control_panel_nav
{
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 95%;
    gap: 20px;
    padding: 15px;
    border-radius: 4px;
    box-sizing: border-box;

}

#control_panel_nav button:not(.active_tab_button):hover{
    transform: scale(1.3);
}

.active_tab_button{
    transform: scale(1.5);
    color: var(--button-highlight)
}

.control_tab_content{
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    height: 90%;
    width: 95%;
    gap: 10px;
    padding-top: var(--verticalGap);;
    padding-bottom: var(--verticalGap);
}

/* ------------------------------- Agents Tab ------------------------------- */
.Agents{
    display: flex;
    flex-direction: column;
    max-height: 100%;
    align-items: center;
}
/* ------------------------------------ - ----------------------------------- */

.Tasks,
.Tools,
.Database{
    height: 95%;
    width: 99%;
    border: 1px solid var(--border-line-color);
}