.documentPanel{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid var(--border-line-color);
    padding : 10px;
    box-sizing: border-box;
}

.documentPreview{
    display: flex;
    flex-grow: 1;
}

.documentsList{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap:5px;
}

.loadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.document{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 5px;
    box-sizing: border-box;
    background-color: var(--background-color-contrast);
    gap:10px;
    color:var(--text-color)
}

.fileInfo{
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
}

.documentName {
    font-size: small;
    word-wrap: break-word;
    word-break: break-all;
    color: var(--text-color);
}

.documentModified {
    color: var(--text-color);
}

.downloadDocument {
    opacity: 50%;
    transition: opacity 0.1s ease;
}
.document:hover .downloadDocument {
    opacity: 1;
  }

.promptFilter{
    display: flex;
    flex-direction: row;
    width: 50%;
}
.promptFilterSearch{
    width: 100%;
    max-width: 250px;
    padding: 10px 15px;
    font-size: 12px;
    color: var(--text-color);
    background: var(--background-color-contrast);
    border: 1px solid var(--border-line-color);
    border-radius: 10px;
    outline: none;
    transition: all 0.3s ease;
}
.promptFilterSearch::placeholder {
    color: var(--background-color-contrast);
    font-style: italic;
    transition: color 0.3s ease;
}

.promptFilterSearch:hover {
    background: var(--background-color-contrast);
}

/* Focus Effect */
.promptFilterSearch:focus {
    border-color: var(--contrast-color); /* Optional accent color */
    background: var(--background-color-contrast);
}

/* Style for when the input has text */
.promptFilterSearch:not(:placeholder-shown) {
    background: var(--background-color-contrast);
}