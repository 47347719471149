.base_button
{
    padding: 5px;
    display: flex;
    width: fit-content;
    background: transparent;
    align-content: center;
    cursor: pointer;
    border: none;
    color: var(--primary-color);
}


.toggle_sidebar{
    position: fixed; /* or absolute, depending on your layout */
    top: 10px; /* Adjust as needed */
    left: 10px; /* Adjust as needed */
    z-index: 1000; /* Ensure it is above other content */
}

.button_icon{
    width: var(--icon-size);
    height: auto;
}

.base_button .button_icon{
    opacity: 50%;
    transform: opacity 0.3s ease;
}

.base_button:hover .button_icon{
    opacity: 100%;
}