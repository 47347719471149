#terminal{
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 40%;
	overflow-y: auto;
}

.terminal_prompt_in {
	font-weight: 500;
	color: var(--prompt-in);
	margin-right: 10px;
}

.terminal_prompt_in b {
	color: var(--prompt-in);
}

.terminal_prompt_out {
	font-weight: 500;
	color: var(--prompt-out);
	margin-right: 10px;
}
.terminal_prompt_out b {
	color: var(--prompt-out-symbol);
}

.terminal_line {
    display: flex;
    height: fit-content;
    width: 100%;
    padding: 12px;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
}

.lastLine{
    border-left: white;
}

.terminal_line_controls{
    display: flex;
    align-self: flex-start;
    height:inherit;
}

.terminal_input_content {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
	gap:5px;
    width: 90%;
}

.terminal-line.current_prompt {
    transform: scale(1.05);
}

.terminal_input_container {
	display: flex;
	flex-direction: column;
    background-color: var(--background-color-contrast);
    resize: none;
    outline: none;
    color: var(--text-color);
    border-radius: 4px;
    width: 100%;
    height: fit-content;
	gap:10px;
    padding: 10px;
    font-size: var(--font-size-prompt);
    font-weight: var(--font-weight-prompt);
    overflow-y:auto;
}

.terminal_input {
    min-height: 20px;
    width: 95%;
    overflow-y: scroll;
    background-color: transparent;
    border: none;
    resize: none;
    outline: none;
    color: var(--text-color);
    font-size: var(--font-size-prompt);
    font-weight: var(--font-weight-prompt);
    line-height: 1.5em;
}

.show-scroll{
	overflow-y: auto;
}

.terminal_output{
	width: 100%;
	min-height: 20px; /* Minimum height */
	max-height: none; /* Ensure there's no max-height restriction */
	overflow-y: hidden; /* Hide vertical scroll */
	background-color: transparent;
	border: none;
	resize: none; /* Disable manual resize */
	outline: none;
    padding: 5px;
    box-sizing: border-box;
	color: var(--text-color);
	border-radius: 4px;

}

.terminal_output pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    overflow: hidden;
}

.terminal_output_content{
    display: flex;
    flex-direction: column;
    border-radius: 4px;
	gap:10px;
    width: 90%;
}

.terminal_output_container{
	display: flex;
	flex-direction: column;
    background-color: transparent;
    border: none;
    resize: none;
    outline: none;
    color: var(--text-color);
    border-bottom: 1px solid white;
    border-radius: 4px;
    max-width: 100%;
	gap:10px;
    padding: 10px;
    font-size: var(--font-size-prompt);
    font-weight: var(--font-weight-prompt);
}

.terminal_input_container_wrapper {
    position: relative;
}

.terminal_input_container {
    padding: 10px;
    background-color: var(--background-color-contrast);
    border-radius: 5px;
}

.terminal_output table {
	border-collapse: collapse;
	align-self: center;
    width: 95%; 
    margin: 20px 0; 
    font-size: 14px; 
    color: var(--primary-color); 
}

.terminal_output th {
    background-color: var(--contrast-color);
    color: var(--text-color);
    padding: 12px 10px;
    border: 1px solid var(--primary-color);
    text-align: left;
}

.terminal_output td {
    padding: 12px 10px;
    border: 1px solid var(--primary-color);
    text-align: left;
}

.terminal_output tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, 0.05);
}

.terminal_output tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.promptPoint {
    margin-top: 10px;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: rgb(71, 167, 8);
    border-radius: 50%;
    transform: translate(-50%, 70%);
    animation: slideDown 0.9s ease forwards;
}

.promptSendingPoint {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: rgb(71, 167, 8);
    border-radius: 50%;
    transform: translate(-50%, 70%);
    animation: beat 0.9s ease-in-out infinite;
}

@keyframes slideDown {
    0% {
        top: 0;
    }
    100% {
        top: 100%;
    }
}

@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}