/* BeatLoader.module.css */
.beat-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Espacio entre puntos */
}

.beat {
    width: 10px;
    height: 10px;
    background-color: var(--loader-color); /* Color de los puntos */
    border-radius: 50%;
    display: inline-block;
    animation: beat 0.9s infinite ease-in-out both;
}

.beat:nth-child(2) {
    animation-delay: 0.1s; /* Retraso para sincronizar animación */
}

.beat:nth-child(3) {
    animation-delay: 0.3s;
}

@keyframes beat {
    0%, 100% {
        transform: scale(0.75); /* Tamaño más pequeño */
        opacity: 0.6; /* Menor opacidad al inicio y final */
    }
    50% {
        transform: scale(1); /* Tamaño completo en el centro del latido */
        opacity: 1;
    }
}
