.command_list{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--spacing-padding);
    box-sizing: border-box;
    width: fit-content;
}

.command_list_option{
    display: flex;
    width: fit-content;
}
.command_list_option:hover{
    cursor:pointer;
}

.commandError{
    color:var(--text-error);
    font-style: italic;
    font-size: small;
}