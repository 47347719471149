.uploaded_files{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.list_items{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 5px;
}

.file_item{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.file_name{
    font-size: medium;
}
.status_icon{
    width: 20px;
    height: auto;
}